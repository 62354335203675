





































































import { Component, Vue } from "vue-property-decorator";
import { IGroupCreate } from "@/interfaces";
import {
  dispatchCreateGroup,
  dispatchGetGroups,
  dispatchGetProject,
  dispatchGetBuildings,
  dispatchGetFilesByProject,
} from "@/store/main/actions";
import {
  readGroups,
  readBuildings,
  readProject,
  readUploadedFiles,
} from "@/store/main/getters";

@Component
export default class Create extends Vue {
  public valid = false;

  public group: IGroupCreate = {
    name: "",
    type: "Group",
    project_id: undefined,
    building_id: undefined,
    file_id: undefined,
    parent_id: undefined,
  };

  public reset() {
    this.group = <IGroupCreate>{};
    this.$validator.reset();
  }
  public showListfloors(floors, building_id) {
    const listObjFloors = floors.filter(
      (floor) => floor.building_id == building_id
    );
    return listObjFloors;
  }
  public showListGroups(groups, floor_id, pathQuery) {
    let listObjGroups = [];
    if (!pathQuery) {
      listObjGroups = groups.filter((group) => group.file_id == floor_id);
    } else {
      listObjGroups = groups.filter(
        (group) =>
          group.file_id == pathQuery.floorplan &&
          group.building_id == pathQuery.building
      );
    }
    return listObjGroups;
  }

  public getFloors() {
    return this.group;
  }

  public cancel() {
    this.$router.back();
  }

  get groups() {
    return readGroups(this.$store);
  }

  get buildings() {
    return readBuildings(this.$store);
  }

  get files() {
    return readUploadedFiles(this.$store);
  }

  get project() {
    return readProject(this.$store);
  }

  get projectId() {
    return Number(this.$router.currentRoute.params.id);
  }

  get pathQuery() {
    let query = this.$router.currentRoute.query;
    if (Object.keys(query).length == 2 && query.building && query.floorplan) {
      return query;
    }
    return;
  }

  public async mounted() {
    await dispatchGetProject(this.$store, this.projectId);
    if (this.pathQuery) {
      this.group.building_id = parseInt(this.pathQuery.building as any);
      this.group.file_id = parseInt(this.pathQuery.floorplan as any);
    }
    if (this.project) {
      await dispatchGetGroups(this.$store, this.projectId);
      await dispatchGetBuildings(this.$store, this.projectId);
      await dispatchGetFilesByProject(this.$store, this.projectId);
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.project) {
        this.group.project_id = this.projectId;
        if (this.pathQuery) {
          this.group.building_id = this.pathQuery.building as any;
          this.group.file_id = this.pathQuery.floorplan as any;
        }
        await dispatchCreateGroup(this.$store, this.group);
        this.$router.back();
      }
    }
  }
}
